export class AudioManager{  
    constructor(product){
        this.product = product;
        this.audioObj;

        this.sounds = new Map();
        this.sounds.set("beepPulse", "./content/alarm-truck-clean.wav");
        this.sounds.set("beepConstant", "./content/alarm-truck-clean-constant.wav");
        BABYLON.Engine.audioEngine.setGlobalVolume(0.1);
    }

    playSoundByName(soundName, isLooping){
        this.audioObj = new Audio(this.sounds.get(soundName));
        this.audioObj.loop = isLooping;
        this.audioObj.volume = 0.2
        this.audioObj.play()
    }

    stopSounds(){
        if(this.audioObj)
            this.audioObj.pause();
    }
}